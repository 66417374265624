const systemName = "FAST_BOOKS";
const baseURL = "https://fms.geezdata.com/api/";
const scmURL = "https://scm.shopspade.com/api/";
const reportURL = "https://task.shopspade.com/api/";
const goodsURL = "https://product.shopspade.com/api/";
const productURL = "https://product.geezdata.com/api/"; //国内商品用
const regionURL = "https://region.shopspade.com/api/";
const shopURL = "https://shop.shopspade.com/api/";
const orderURL = "https://order.shopspade.com/api/";
const inventoryURL = "https://inventory.shopspade.com/api/";
const idaasURL = "https://idaas-cdn.shopspade.com";

const scmWebEntry = "https://scm.shopspade.com";
const inventoryWebEntry = "https://inventory.shopspade.com";
const wmshubWebEntry = "https://wmshub.shopspade.com";
const shopWebEntry = "https://shop.shopspade.com";
const goodsWebEntry = "https://product.geezdata.com"; //商品
const orderWebEntry = "https://order.geezdata.com"; //订单

const singleSignOnURL =
  idaasURL +
  "/api/open/oauth/authorize?app_key=spade_gateway_001&response_type=code&scope=FAST_BOOKS&terminal=PC&redirect_uri=" +
  window.location.origin +
  "&idaas_uri=" +
  idaasURL;

const singleSignOutURL = idaasURL + "/logout";

export {
  systemName,
  baseURL,
  scmURL,
  reportURL,
  goodsURL,
  regionURL,
  productURL,
  shopURL,
  orderURL,
  inventoryURL,
  idaasURL,
  singleSignOnURL,
  singleSignOutURL,
  scmWebEntry,
  inventoryWebEntry,
  wmshubWebEntry,
  shopWebEntry,
  goodsWebEntry,
  orderWebEntry,
};
